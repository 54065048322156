@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ffffff;
}

input[type="radio"]:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: flex;
  visibility: visible;
  border: 1px solid rgb(215, 215, 215);
}

input[type="radio"]:checked:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  position: flex;
  background-color: #8ddae9;
  content: "";
  display: flex;
  visibility: visible;
  border: 1px solid white;
}

@font-face {
  font-family: "Modelica";
  src: url("./fonts/BwModelica-Regular.woff2");
}

@font-face {
  font-family: "Modelica-medium";
  src: url("./fonts/BwModelica-Medium.woff2");
  font-weight: 100;
}

html {
  box-sizing: border-box;
  --bgColorMenu: #1d1d27;
  --duration: 0.7s;
}

html *,
html *::before,
html *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  display: flex;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: background-color var(--duration);
}

.menu {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100%;
  margin-right: auto;
  /* Works well with 100% width  */
  width: 80px;
  font-size: 1.5em;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--bgColorMenu);
  border-radius: 7%;
}

.menu__item {
  all: unset;
  z-index: 100;
  flex-grow: 0.15;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  align-items: center;
  will-change: transform;
  justify-content: center;
  padding: 0.55em 0 0.85em;
  transition: transform var(--timeOut, var(--duration));
}

.menu__item::before {
  content: "";
  z-index: -1;
  width: 4.2em;
  height: 4.2em;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  transition: background-color var(--duration), transform var(--duration);
}

.menu__item.active {
  transform: translate3d(0.8em, 0, 0);
}

.menu__item.active::before {
  transform: scale(0.8);
  background-color: #5197de;
}

.icon {
  width: 2.6em;
  height: 2.6em;
  stroke: white;
  fill: transparent;
  stroke-width: 1pt;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 400;
}

.menu__item.active .icon {
  animation: stroke 1.5s reverse;
}

@keyframes strok {
  100% {
    stroke-dashoffset: 400;
  }
}

.menu__border {
  bottom: 91.5%;
  width: 10.9em;
  height: 2.4em;
  position: absolute;
  clip-path: url(#menu);
  will-change: transform;
  background-color: var(--bgColorMenu);
  transition: transform var(--timeOut, var(--duration));
  margin-left: 5.69em;
  transform: rotate(90deg);
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.svg-container {
  width: 0;
  height: 0;
}

@media only screen and (max-width: 1000px) {
  .menu {
    width: 75px;
  }
  .menu__border {
    bottom: 90.5%;
    margin-left: 5.5em;
  }
}

@media only screen and (max-width: 800px) {
  body {
    background-color: lightblue;
  }
  .menu {
    width: 60px;
  }
  .menu__border {
    bottom: 100%;
    margin-left: 7em;
  }
}

@media screen and (max-width: 50em) {
  .menu {
    font-size: 0.8em;
  }
}
